<template>
    <van-row class="MyRangeDate">
        <van-col span="14">
            <van-field v-model="startValue"
                       :border="false"
                       :label="label"
                       :placeholder="startPlaceholder"
                       readonly
                       v-bind="$attrs"
                       @click="showStartPicker = true"
            />
            <van-popup v-model="showStartPicker" position="bottom" round>
                <van-datetime-picker :formatter="formatter"
                                     :max-date="maxDate"
                                     :title="startPlaceholder"
                                     type="date"
                                     @cancel="showStartPicker = false"
                                     @confirm="onStartConfirm"
                />
            </van-popup>
        </van-col>
        <van-col class="MyRangeDate__separator" span="2">—</van-col>
        <van-col span="8">
            <van-field v-model="endValue"
                       :border="false"
                       :colon="false"
                       :placeholder="endPlaceholder"
                       readonly
                       @click="showEndPicker = true"
            />
            <van-popup v-model="showEndPicker" position="bottom" round>
                <van-datetime-picker :formatter="formatter"
                                     :min-date="minDate"
                                     :title="endPlaceholder"
                                     type="date"
                                     @cancel="showEndPicker = false"
                                     @confirm="onEndConfirm"
                />
            </van-popup>
        </van-col>
    </van-row>
</template>

<script>
    import {timeFormat} from "@/utils/tools";

    export default {
        name: "MyRangeDate",
        props: {
            value: {
                type: Array,
                required: true
            },
            label: String,
            type: String,
            startPlaceholder: {
                type: String,
                default: '选择起始日期'
            },
            endPlaceholder: {
                type: String,
                default: '选择结束日期'
            },
            valueFormat: String
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                startValue: '',
                endValue: '',
                showStartPicker: false,
                showEndPicker: false,
                // minDate: new Date(2020, 0, 1),
                // maxDate: new Date(2021, 10, 11),
            }
        },
        watch: {
            value: {
                handler() {
                    this.setInputValue();
                }
            }
        },
        mounted() {
            this.setInputValue();
        },
        computed: {
            minDate() {
                if (!this.startValue) return undefined;
                return new Date(this.startValue);
            },
            maxDate() {
                if (!this.endValue) return undefined;
                return new Date(this.endValue);
            }
        },
        methods: {
            fmt(date) {
                if (!date) return '';
                return timeFormat(date, 'yy-MM-dd');
            },
            // 格式化
            formatter(type, val) {
                if (type === 'year') {
                    return val + '年';
                }
                if (type === 'month') {
                    return val + '月';
                }
                if (type === 'day') {
                    return val + '日';
                }
                return val;
            },
            // 开始时间确认回调
            onStartConfirm(value) {
                let date = this.fmt(Date.parse(value));
                this.startValue = date;
                this.showStartPicker = false;
                this.onInput();
            },
            // 结束时间确认回调
            onEndConfirm(value) {
                let date = this.fmt(Date.parse(value));
                this.endValue = date;
                this.showEndPicker = false;
                this.onInput();
            },
            // 设置默认值
            setInputValue() {
                let [start = '', end = ''] = this.value;
                this.startValue = this.valueFormat === 'date' ? start : this.fmt(start);
                this.endValue = this.valueFormat === 'date' ? end : this.fmt(end);
            },
            // input事件，同步绑定值
            onInput() {
                let value = this.getInputValue([this.startValue, this.endValue]);
                this.$emit('input', value);
            },
            getInputValue(value = []) {
                let [start, end] = value;
                let startDate = start ? new Date(start) : '';
                let endDate = end ? new Date(end) : '';
                // 获取时间戳
                let startTime = startDate ? startDate.getTime() : '';
                let endTime = endDate ? endDate.getTime() : '';
                // 时间戳
                if (this.valueFormat === 'timestamp') {
                    return [startTime, endTime];
                    // 时间格式
                } else if (this.valueFormat === 'date') {
                    return [this.fmt(startTime), this.fmt(endTime)];
                }
                return [startDate, endDate];
            }
        }
    }
</script>

<style lang="scss" scoped>
    .MyRangeDate {
        &__separator {
            line-height: 44px;
            text-align: center;
        }
    }
</style>